import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Icon, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaSeedling, FaGifts, FaChild } from "react-icons/fa";
import { MdFitnessCenter } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ClearPath
			</title>
			<meta name={"description"} content={"Your Cleaning Expert"} />
			<meta property={"og:title"} content={"ClearPath"} />
			<meta property={"og:description"} content={"Your Cleaning Expert"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.4) 0%,--color-dark 100%),url(https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z) center center/cover no-repeat"
			min-height="85vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-22"
			padding="88px 0 88px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 0px 0px 0px"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-align-items="center"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
			>
				<Text
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
					color="#FFFFFF"
					font="--lead"
					width="100%"
					sm-margin="0px 0px 12px 0px"
					letter-spacing="2px"
				>
					Clearing the Path to Your Peace of Mind
				</Text>
			</Box>
			<Box
				md-margin="0px 0px 0px 0px"
				width="100%"
				flex-direction="row"
				align-items="flex-end"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
			>
				<Text
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					color="--light"
					width="30%"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					font="--headline2"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Your Cleaning Expert
				</Text>
				<Text
					lg-text-align="center"
					margin="0px 0px 0px 0px"
					color="#FFFFFF"
					font="--lead"
					width="30%"
					lg-width="100%"
				>
					At ClearPath Cleaning Services, we strive to provide exceptional cleaning services for your home, apartment, or office, ensuring that your environment is not just clean but a space where you can thrive and find peace.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				Welcome to ClearPath Cleaning Services
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						In the constant ebb and flow of life, a clean and orderly space is a source of comfort and clarity. At ClearPath Cleaning Services, we strive to provide exceptional cleaning services for your home, apartment, or office, ensuring that your environment is not just clean but a space where you can thrive and find peace.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="#35363a">
						<Strong>
							Trust us, and we will pave the way to a cleaner and more serene life and work environment.
						</Strong>
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Eco-Friendly Cleaning
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Reliability and Respect
					</Text>
					<Text margin="0px 0px 0px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Unwavering Quality
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="--color-darkL1"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-26"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Why Choose ClearPath Cleaning Services?
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					font="--base"
					text-align="center"
					color="--lightD2"
					lg-max-width="720px"
					sm-text-align="left"
				>
					ClearPath stands out for its commitment to excellence, customer care, and unparalleled cleaning expertise
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="36px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
				>
					<Box
						padding="24px 24px 48px 24px"
						background="--color-darkL2"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-red"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="fa" icon={FaSeedling} size="28px" color="--red" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--light">
								Customized Cleaning Solutions
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--lightD2">
							We understand that every space has its own story and needs. Our services are specially designed to meet your cleaning requirements.
						</Text>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						background="--color-darkL2"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-red"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="fa" icon={FaGifts} size="28px" color="--red" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--light">
								Professional and Courteous Staff
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--lightD2">
							Our team is the heart of our service. Each member is carefully selected and trained to deliver outstanding cleaning results with the utmost respect for your space.
						</Text>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						background="--color-darkL2"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-red"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="md" icon={MdFitnessCenter} size="28px" color="--red" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--light">
								Eco-Friendly
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--lightD2">
							We are committed to protecting your health and the environment by using eco-friendly cleaning methods and products.
						</Text>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						background="--color-darkL2"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-red"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="fa" icon={FaChild} size="28px" color="--red" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--light">
								Satisfaction Guaranteed
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--lightD2">
							We strive for perfection in every cleaning. If you are ever dissatisfied, we promise to make it right.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 0 0" quarkly-title="List-1">
			<Text margin="0px 0px 15px 0px" text-align="center" font="normal 600 64px/1.2 --fontFamily-sans" sm-font="normal 600 56px/1.2 --fontFamily-sans">
				Services
			</Text>
			<Section padding="0 0 100px 0" quarkly-title="List-3">
				<Override slot="SectionContent" margin="0px 0 0px 0" width="100%" />
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="0 34px"
					md-grid-template-columns="1fr"
					md-grid-gap="36px 0"
				>
					<Box min-width="100px" min-height="100px">
						<Text
							margin="0px 0px 0 0px"
							font="normal 500 28px/1.2 --fontFamily-sans"
							height="400px"
							display="flex"
							align-items="flex-end"
							padding="0px 15px 35px 15px"
							background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65e71749009abe001f042f63/images/2-2.jpg?v=2024-03-06T09:51:00.301Z) left/cover repeat scroll padding-box"
							color="--light"
							lg-height="300px"
							md-height="400px"
						>
							"Home Sweet Home" Cleaning
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text
							margin="0px 0px 0 0px"
							font="normal 500 28px/1.2 --fontFamily-sans"
							height="400px"
							display="flex"
							align-items="flex-end"
							padding="0px 15px 35px 15px"
							background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65e71749009abe001f042f63/images/4-3.jpg?v=2024-03-06T09:51:00.295Z) 50% 0% /cover repeat scroll padding-box"
							color="--light"
							lg-height="300px"
							md-height="400px"
						>
							Office and Commercial Spaces
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text
							margin="0px 0px 0 0px"
							font="normal 500 28px/1.2 --fontFamily-sans"
							height="400px"
							display="flex"
							align-items="flex-end"
							padding="0px 15px 35px 15px"
							background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-1.jpg?v=2024-03-06T09:51:00.277Z) 50% 0% /cover repeat scroll padding-box"
							color="--light"
							lg-height="300px"
							md-height="400px"
						>
							Deep Cleaning
						</Text>
					</Box>
				</Box>
				<Section
					background="linear-gradient(0,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,url(https://uploads.quarkly.io/65e71749009abe001f042f63/images/3-2.jpg?v=2024-03-06T09:51:00.353Z) center center/cover no-repeat"
					lg-padding="50px 0 50px 0"
					quarkly-title="Hero-21"
					padding="88px 0 88px 0"
					max-height="50vh"
					margin="2rem 0 0 0"
				>
					<Override
						slot="SectionContent"
						flex-direction="column"
						align-items="center"
						justify-content="center"
						align-self="center"
					/>
					<Text
						lg-text-align="center"
						text-align="center"
						width="100%"
						margin="0px 0px 36px 0px"
						color="--light"
						font="--lead"
						lg-width="100%"
					>
						<Strong>
							Striving for perfection and passionately pursuing cleanliness, we have created a service that goes beyond the ordinary, offering the perfect blend of quality,

 reliability, and personalized care.
						</Strong>
					</Text>
					<Link
						color="--light"
						font="--lead"
						sm-margin="0px 0px 20px 0px"
						sm-width="100%"
						hover-color="--dark"
						border-width="2px"
						border-style="solid"
						href="/services"
						text-decoration-line="initial"
						border-radius="75px"
						background="--color-dark"
						border-color="--color-dark"
						padding="10px 23px 10px 23px"
						margin="0px auto 0px auto"
						transition="background-color 0.3s linear 0s"
						hover-background="rgba(4, 8, 12, 0)"
						sm-text-align="center"
						width="100%"
						text-align="center"
						align-self="center"
					>
						Services
					</Link>
				</Section>
			</Section>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				padding="0px 10% 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
					lg-margin="0px 0px 48px 0px"
					sm-margin="0px 0px 24px 0px"
				>
					Your space is a canvas for the most cherished moments of your life.
				</Text>
			</Box>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="flex-start"
				padding="0px 0px 32px 16px"
				lg-width="100%"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="flex-start"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					width="80%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					border-radius="24px"
					margin="0px 0px 34px 0px"
					lg-width="50%"
					lg-margin="0px 32px 34px 0px"
					lg-padding="0px 0px 75% 0px"
					sm-margin="0px 0px 24px 0px"
					sm-width="100%"
					sm-padding="0px 0px 135% 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09:51:00.307Z"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top={0}
						left={0}
						right={0}
						bottom={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09%3A51%3A00.307Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09%3A51%3A00.307Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09%3A51%3A00.307Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09%3A51%3A00.307Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09%3A51%3A00.307Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09%3A51%3A00.307Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-2.jpg?v=2024-03-06T09%3A51%3A00.307Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box lg-width="40%" sm-width="100%">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--lead"
						lg-text-align="left"
						display="flex"
						align-items="flex-start"
					>
						When you choose ClearPath Cleaning Services, you are not just cleaning your environment – you are creating a backdrop for memories, productivity, and peace.
					</Text>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				align-items="flex-start"
				overflow-x="hidden"
				overflow-y="hidden"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-justify-content="flex-start"
				margin="0px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				sm-flex-direction="column"
				sm-justify-content="center"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 20% 0px"
					border-radius="24px"
					lg-width="auto"
					lg-margin="0px 32px 34px 0px"
					margin="0px 32px 0px 0px"
					min-width="188px"
					min-height="188px"
					sm-margin="0px 32px 24px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09:51:00.299Z"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top={0}
						left={0}
						right={0}
						bottom={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09%3A51%3A00.299Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09%3A51%3A00.299Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09%3A51%3A00.299Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09%3A51%3A00.299Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09%3A51%3A00.299Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09%3A51%3A00.299Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71749009abe001f042f63/images/4-1.jpg?v=2024-03-06T09%3A51%3A00.299Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="left"
					display="flex"
					align-items="flex-start"
					sm-text-align="left"
					lg-width="50%"
					sm-width="100%"
				>
					Let us help you create a space that reflects your highest standards and personal story.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde662009abe001f03e3dd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});